import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill"
import { Helmet } from "react-helmet";
import { ButtonArrowRight } from "../components/Button";
import * as Markdown from 'react-markdown';
import Layout from "../components/layout";
import SEO from "../components/seo";
import CtaGrid from "../components/Home/ctaGrid";
import HeroCtaMedia from "../components/Home/ctaMedia";
import HeroCarousel from "../components/Content/HeroCarousel"


const LatestPosts = ({ blog }) => {

    const postNodes = blog.sort((a, b) => new Date(b.node.publishDate).getTime() - new Date(a.node.publishDate).getTime());
    // const posterImage = heroVideo ? `https://img.youtube.com/vi/${heroVideo.split('v=')[1].split('&')[0]}/maxresdefault.jpg` : null;

    return (
        <section id="NewsFeed" className="uk-background-earth-dark">
            <div className="uk-container uk-container-expand">
                <div className="uk-padding-small">
                    <div className="uk-padding-remove-vertical uk-margin-large-top uk-margin-large-bottom uk-flex uk-flex-center">
                        <div className="uk-text-center">
                            <p className="h3__large uk-text-earth-light uk-margin-xsmall">The knowledge to make you smarter.</p>
                            <p className="uk-text-lead uk-text-earth-lighter uk-margin-remove">We dig deeper to bring insight in the moment.</p>
                        </div>
                    </div>
                    <div data-uk-slider>
                        <div className="uk-slider-container uk-slider-container-offset">
                            <div className="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@s uk-grid uk-grid-small uk-grid-match" uk-height-match="target: .uk-card-body">
                                {postNodes.slice(0, 6).map(post => <div key={post.node.slug} className="uk-margin-bottom">
                                    <div className="uk-card uk-card-default uk-card-small uk-border-rounded uk-overflow-hidden">
                                        <div className="uk-card-media-top uk-overflow-hidden">
                                            {post.node.heroVideo ?
                                                <img
                                                    style={{ objectFit: "cover", objectPosition: "center", width: "100%", height: "100%", minHeight: "500px" }}
                                                    src={`https://img.youtube.com/vi/${post.node.heroVideo.split('v=')[1].split('&')[0]}/maxresdefault.jpg`}
                                                />
                                                :
                                                post.node.heroImage ?
                                                    <Img
                                                        style={{ height: "100%", minHeight: "500px" }}
                                                        fluid={post.node.heroImage.localFile.childImageSharp.fluid}
                                                        alt={post.node.title}
                                                        objectFit="cover"
                                                        objectPosition="50% 50%" />
                                                    :
                                                    <p>No Image</p>
                                            }
                                        </div>
                                        <div className="uk-card-body">
                                            <p className="uk-text-medium uk-text-bold">{post.node.title}</p>
                                            <Markdown
                                                source={post.node.copy.childMarkdownRemark.html}
                                                escapeHtml={false} />
                                        </div>
                                        <div className="uk-card-footer">
                                            <ButtonArrowRight
                                                url={`/feed/${post.node.slug}`}
                                                label="Read this"
                                                css={`uk-button-small cta-button uk-text-grape`}
                                                color={`rgb(96, 74, 113)`} />
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="uk-container uk-flex uk-flex-center uk-light">
                            <ul className="uk-slider-nav uk-dotnav uk-padding" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function IndexPage({ data, location, ...props }) {
    const content = data.contentfulHomePage;
    const blog = data.allContentfulBlogPost.edges;
    const title = `${content.pageTitle}`;
    const description = `${content.metaDescription}`;
    const metaImage = `https:${content.metaImage.file.url}`;
    const ctaCarousel = data.allContentfulHeroCarousel.nodes[0].heroCtaModules
    const siftUI = data.allFile.nodes[0];

    return (
        <Layout location={location}>
            <SEO title={title} />
            <Helmet>
                <meta name="image" content={metaImage} />
                <meta property="og:image" content={metaImage} />
                <meta name="twitter:image" content={metaImage} />
            </Helmet>

            <section className="uk-section uk-section-default uk-section-large uk-padding-remove-bottom">
                <div className="uk-container uk-margin-large-bottom uk-flex uk-flex-center">
                    <div className="uk-container">
                        <div className="uk-width-3-5@s uk-margin-large-top">
                            <h1 className="h1__xxlarge uk-text-primary">Decision free execution.</h1>
                            <p className="uk-text-lead">
                                Remove the emotion and guess work from your organization's decision making processes and execute with precision.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <HeroCtaMedia data={data} /> */}

            <section className="uk-section uk-section-large uk-background-grape">
                <div className={`uk-container uk-container-expand uk-flex uk-flex-center`}>
                    <div className="uk-width-1-1 uk-text-center">
                        <p className="uk-text-xlarge uk-text-wheat-light uk-padding">
                            We live in time of unprecedented volatility.<br />A world of unpredictable change.
                        </p>
                    </div>
                </div>
            </section>
            <HeroCarousel heroCtaModules={ctaCarousel} slug={data.allContentfulHeroCarousel.nodes[0].slug} />

            <section className="uk-section uk-section-large uk-background-wheat-lighter">
                <div className="uk-container uk-container-expand uk-flex uk-flex-center">
                    <div className="uk-width-5-6 uk-width-4-5@m">
                        <div className="uk-width-1-2@s uk-margin-large-top uk-margin-large-bottom">
                            <p className="h1__xlarge uk-text-primary">Emotionless.</p>
                            <p className="uk-text-lead uk-text-primary">
                                Develop a playbook of pre-determined actions and execute without emotion.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <CtaGrid data={data} />

            <section className="uk-section uk-section-large uk-background-grape">
                <div className="uk-container uk-container-expand uk-flex uk-flex-center">
                    <div className="uk-width-5-6 uk-width-4-5@m">
                        <div className="uk-grid uk-grid-large uk-grid-row-small uk-child-width-1-2@s">
                            <div>
                                <div>
                                    <Img
                                        fluid={siftUI.childImageSharp.fluid}
                                        alt="More Accountable."
                                        objectFit="cover"
                                        objectPosition="50% 50%"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                            <div className="uk-flex uk-flex-middle uk-flex-center">
                                <div className="uk-width-4-5@s">
                                    <p className="h1__large uk-text-grape-lighter">Clarity<br />in the chaos.</p>
                                    <p className="uk-text-lead uk-light uk-text-grape-lightest">Sift helps filter out the noise and unnecessary in the sea of information influencing your business.</p>
                                    <div className="uk-margin-top">
                                        <ButtonArrowRight
                                            url={`/sift/`}
                                            label="Learn more"
                                            css={`uk-button-large cta-button uk-text-grape-lightest`}
                                            color={`#DFDBE3`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <LatestPosts blog={blog} />

        </Layout>
    );
}

export default IndexPage;

export const indexQuery = graphql`
    query indexQuery {
        contentfulHomePage(site: {eq: "SoleraAdvisors"}) {
            content {
                json
            }
            canonicalLink
            metaDescription
            metaImage {
                file {
                    url
                }
            }
            metaKeywords
            navigationLabel
            pageTitle
        }
        allContentfulBlogPost(filter: {site: {eq: "SoleraAdvisors"}}) {
            edges {
              node {
                copy {
                  childMarkdownRemark {
                    html
                  }
                }
                slug
                title
                publishDate
                topic
                heroVideo
                heroImage {
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
            }
          }
          allFile(filter: {name: {eq: "sift-ui"}}) {
            nodes {
              childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
              name
            }
          }
          allContentfulHeroCarousel(filter: {slug: {eq: "home-cta-carousel"}}) {
            nodes {
              slug
              heroCtaModules {
                templateLayout
                slug
                headline
                subHeadline {
                  childMarkdownRemark {
                    html
                  }
                }
                imageFile {
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 1600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
            }
          }
    }`
