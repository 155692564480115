import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import * as Markdown from 'react-markdown';

const HeroDefault = ({ data }) => {
  const { headline, subHeadline, slug } = data;
  const leader = subHeadline?.childMarkdownRemark?.html ?? "";

  const defaultCTAImage = useStaticQuery(graphql`
    query defaultCTAImage {
      allContentfulHeroCta {
        nodes {
          slug
          landing_page {
            slug
          }
          imageFile {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  const img = defaultCTAImage.allContentfulHeroCta.nodes.find(d => d.slug === slug);

  return (
    <BackgroundImage
      Tag="div"
      fluid={img.imageFile.localFile.childImageSharp.fluid}
      preserveStackingContext={true}
      className="uk-section uk-section-xlarge"
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: "rgba(58, 44, 68, 0.6)",
        backgroundPosition: `center center`
      }}>
      <div id={slug} className="uk-container uk-padding uk-light">
        <div className="uk-flex uk-flex-middle uk-flex-left uk-height-1-1">
          <div className="uk-padding-small uk-width-5-6@m">
            <h1 className="">{headline}</h1>
            <Markdown
              source={leader}
              escapeHtml={false}
            />
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default HeroDefault;
