import React from "react"
import HeroDefault from "../Hero/HeroDefault";
import style from "../Hero/Hero.module.css";

const HeroCarousel = ({collectionName, heroCtaModules, slug}) => {

    return (
        <div
            className={`uk-position-relative uk-light`}
            data-uk-slideshow="autoplay: true;autoplay-interval: 5000;animation: fade; min-height: 512; max-height: 800; pause-on-hover: false">
                <ul className="uk-slideshow-items uk-grid uk-grid-collapse uk-grid-match uk-child-width-1-1">
                    {heroCtaModules.map((item, id) =>
                        <li key={item.slug}>
                            <HeroDefault data={item} />
                        </li>
                    )}
                </ul>
                <ul className="uk-position-bottom-center uk-position-large uk-slideshow-nav uk-dotnav"></ul>
        </div>
    )
}

export default HeroCarousel;
