import React from "react";
import { useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill"
import { ButtonArrowRight } from "../Button";

function CtaGrid({ data, location, ...props }) {
    const content = data.contentfulHomePage;
    const blog = data.allContentfulBlogPost.edges;
    const title = `${content.pageTitle}`;
    const description = `${content.metaDescription}`;
    const metaImage = `https:${content.metaImage.file.url}`;

    const localImage = useStaticQuery(graphql`
    fragment localImage on File {
        childImageSharp {
            fluid(maxWidth: 600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    query {
      dairy: file(relativePath: { eq: "home/dairy.png" }) {
        ...localImage
      }
      oil: file(relativePath: { eq: "home/oil.png" }) {
        ...localImage
      }
      palm: file(relativePath: { eq: "home/palm.png" }) {
        ...localImage
      }
      wheat: file(relativePath: { eq: "home/wheat.png" }) {
        ...localImage
      }
    }
  `);


    return (
        <>
            <section>
                <div className="uk-background-grape-darker">
                    <div className="home__grid__fullwidth uk-grid uk-grid-collapse uk-grid-match uk-child-width-1-2@s">
                        <div className="uk-flex-first uk-overflow-hidden">
                            <Img
                                fluid={localImage.dairy.childImageSharp.fluid}
                                alt="More Agile."
                                objectFit="cover"
                                objectPosition="50% 50%"
                                loading="eager"
                                className={`uk-animation-kenburns uk-transform-origin-center`}
                            />
                        </div>
                        <div className="uk-flex uk-flex-center uk-flex-middle uk-height-min-large">
                            <div className="uk-width-4-5 uk-width-2-3@s">
                                <p className="h1__large uk-light uk-text-grape-light">More<br />agile.</p>
                                <p className="uk-text-lead uk-light uk-text-grape-lighter">
                                    Move quickly when opportunity knocks, or a threat emerges.
                                </p>
                                {/* <p>
                                    <ButtonArrowRight
                                        url={`#`}
                                        label="Learn more"
                                        css={`uk-button-large cta-button uk-text-grape-lighter`}
                                        color={`#AFA4B8`} />
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="uk-background-earth-darker">
                    <div className="home__grid__fullwidth uk-grid uk-grid-collapse uk-grid-match uk-child-width-1-2@s">
                        <div className="uk-flex uk-flex-center uk-flex-middle uk-height-min-large">
                            <div className="uk-width-4-5 uk-width-2-3@s">
                                <p className="h1__large uk-light uk-text-earth-light">More<br />precise.</p>
                                <p className="uk-text-lead uk-light uk-text-earth-lighter">
                                Execute with precision based on your organization's unique objectives and executional parameters&ndash;exactly what, when and how much.
                                </p>
                                {/* <p>
                                    <ButtonArrowRight
                                        url={`#`}
                                        label="Learn more"
                                        css={`uk-button-large cta-button uk-text-earth-lighter`}
                                        color={`#D3DBDB`} />
                                </p> */}
                            </div>
                        </div>
                        <div className="uk-flex-first uk-flex-last@s uk-overflow-hidden">
                            <Img
                                fluid={localImage.palm.childImageSharp.fluid}
                                alt="More Precise."
                                objectFit="cover"
                                objectPosition="50% 50%"
                                loading="eager"
                                className={`uk-animation-kenburns uk-transform-origin-center`}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="uk-background-wine-darker">
                    <div className="home__grid__fullwidth uk-grid uk-grid-collapse uk-grid-match uk-child-width-1-2@s">
                        <div className="uk-flex-first uk-overflow-hidden">
                            <Img
                                fluid={localImage.oil.childImageSharp.fluid}
                                alt="More Actionable."
                                objectFit="cover"
                                objectPosition="50% 50%"
                                loading="eager"
                                className={`uk-animation-kenburns uk-transform-origin-center`}
                            />
                        </div>
                        <div className="uk-flex uk-flex-center uk-flex-middle uk-height-min-large">
                            <div className="uk-width-4-5 uk-width-2-3@s">
                                <p className="h1__large uk-light uk-text-wine">More<br />actionable.</p>
                                <p className="uk-text-lead uk-light uk-text-wine-lighter">
                                    Pre-planned, pre-approved, and budgeted for in advance, so you're ready to act.
                                </p>
                                {/* <p>
                                    <ButtonArrowRight
                                        url={`#`}
                                        label="Learn more"
                                        css={`uk-button-large cta-button uk-text-wine-lighter`}
                                        color={`#B69798`} />
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="uk-background-wheat-darker">
                    <div className="home__grid__fullwidth uk-grid uk-grid-collapse uk-child-width-1-2@s">
                        <div className="uk-flex uk-flex-center uk-flex-middle uk-height-min-large">
                            <div className="uk-width-4-5 uk-width-2-3@s">
                                <p className="h1__large uk-light uk-text-wheat">More<br />accountable.</p>
                                <p className="uk-text-lead uk-light uk-text-wheat-lighter">
                                    Key stakeholder engagement throughout the process ensures organizational alignment.
                                </p>
                                {/* <p>
                                    <ButtonArrowRight
                                        url={`#`}
                                        label="Learn more"
                                        css={`uk-button-large cta-button uk-text-wheat-lighter`}
                                        color={`#E4DBCD`} />
                                </p> */}
                            </div>
                        </div>
                        <div className="uk-flex-first uk-flex-last@s uk-overflow-hidden">
                            <Img
                                fluid={localImage.wheat.childImageSharp.fluid}
                                alt="More Accountable."
                                objectFit="cover"
                                objectPosition="50% 50%"
                                loading="eager"
                                style={{ height: "100%" }}
                                className={`uk-animation-kenburns uk-transform-origin-center`}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CtaGrid;